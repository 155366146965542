import Navbar from '../common/Navbar';
import Landing from './Landing';
import CallToAction from './CallToAction';
import Journey from './Journey';
import Footer from '../common/Footer';

function Process() {
    return (
      <>
        <Navbar />
        <Landing />
        <Journey />
        <CallToAction />
        <Footer/>
      </>
    );
  }


export default Process;