import React from "react";
import { Link as ScrollLink } from 'react-scroll';
import { FaArrowRight } from "react-icons/fa6";
import { motion } from "framer-motion";

const animation = {
    offscreen: {opacity:0},
    onscreen: {opacity:1, transition: {duration: 1.25, type: 'tween'}},
};

const AboutLanding = () => {
    return (
        <div className="max-w-[100%] min-w-[354px] h-fit overflow-hidden lg:mb-12">
            <div className="grid lg:grid-cols-2 gap-4 px-16 pt-5 md:pt-10 sm:place-items-center ">
                <motion.div className="hero-heading gradient-text span text-5xl sm:text-6xl md:text-8xl lg:text-[11vw] place-items-center "
                    initial = "offscreen"
                    whileInView= "onscreen"
                    variants = {animation}
                    viewport={{ once: true}}
                >
                    <h1>This is Enviion.</h1>
                </motion.div>
                <div className="hero-text text-base md:text-lg lg:text-xl md:px-10">
                    <motion.p className="pb-4 text-[#333]"
                        initial = "offscreen"
                        whileInView= "onscreen"
                        variants = {animation}
                        viewport={{ once: true}}
                    >
                        We are a digital transformation partner driven by a commitment to propel businesses to new heights through innovative technology and cultivating lasting growth.
                        At the heart of our mission is a dedication to fostering everlasting customer relations through mutual trust.  
                    </motion.p>
                    <motion.div className="reach-out uppercase text-center whitespace-nowrap text-sm bg-transparent"
                        initial = "offscreen"
                        whileInView= "onscreen"
                        variants = {animation}
                        viewport={{ once: true}}
                    >
                        <a href="/contact" className='flex items-center pointer-cursor'>
                            <span className='mr-2'><FaArrowRight/> </span>
                            <span>Get In Touch</span>
                        </a>
                    </motion.div>
                </div>
            </div>
        </div>
    );
}

export default AboutLanding;

