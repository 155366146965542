import React from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

const CarouselCard = ({ image, title, description, icons }) => {
  return (
    <div className="p-10 text-[#f5f5f5]">
      <div className="flex justify-center">
        <img src={image} alt="image" className="h-[20vh] md:h-[40vh]" />
      </div>
      <h1 className="hero-heading text-2xl md:text-4xl lg:text-6xl pt-10 text-center">
        {title}
      </h1>
      <motion.p className="hero-text text-lg md:text-xl  p-5 lg:px-40 text-center">
        {description}
      </motion.p>
      <div className="flex justify-center items-center space-x-4 sm:space-x-10 text-4xl sm:text-5xl opacity-75 py-5">
        {icons.map((icon, index) => React.cloneElement(icon, { key: index }))}
      </div>
    </div>
  );
};

CarouselCard.propTypes = {
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    icons: PropTypes.arrayOf(PropTypes.element).isRequired,
  };

export default CarouselCard;