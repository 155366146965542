import React from "react";
import { motion } from "framer-motion";
import ContactForm from './ContactForm';
import { Element } from "react-scroll";

const heading = {
    offscreen: {opacity:0, x:-50},
    onscreen: {opacity:1, x:0, transition: {duration: 1.25, type: 'tween'}},
};
const ctaText = {
    offscreen: {opacity:0, x:-50},
    onscreen: {opacity:1, x:0, transition: {duration: 1.25, type: 'tween', delay: 0.3}},
};

const ctaButton = {
    offscreen: {opacity:0},
    onscreen: {opacity:1, transition: {duration: 1.25, type: 'tween', delay: 0.4}},
};

const CallToAction = () => {

    return(
        <Element name="contact">
            <div className="max-w-[100%] min-w-[354px] bg-[#1a4d4b]">
                <div className="grid grid-flow-row lg:flex py-16 px-10 lg:px-40 justify-between items-center">
                    <div>
                        <motion.h1 className="hero-heading text-4xl lg:text-6xl text-[#f5f5f5]"
                            initial = "offscreen"
                            whileInView= "onscreen"
                            variants = {heading}
                            viewport={{ once: true}}
                        >
                            Let's Work Together.
                        </motion.h1>
                        <motion.p className="hero-text text-lg text-[#f5f5f5] pt-4 pb-14"
                            initial = "offscreen"
                            whileInView= "onscreen"
                            variants = {ctaText}
                            viewport={{ once: true}}
                        >
                            Your business is one-of-a-kind, and so should be your digital presence. 
                            Let's build it together.
                        </motion.p>
                    </div>
                    <motion.div className="flex items-center lg:pl-24"
                        initial = "offscreen"
                        whileInView= "onscreen"
                        variants = {ctaButton}
                        viewport={{ once: true}}
                    >
                        <ContactForm />
                    </motion.div>
                </div>
            </div>
        </Element>
    )
}

export default CallToAction