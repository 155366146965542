import React from "react";
import Home from './components/home/Home.jsx';
import About from './components/about/about.jsx';
import Contact from './components/contact/contact.jsx';
import Services from './components/services/Services.jsx';
import Process from "./components/process/Process.jsx";
import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/about" element={<About/>} />
      <Route path="/contact" element={<Contact/>} />
      <Route path="/services" element={<Services/>} />
      <Route path="/process" element={<Process/>}/>
    </Routes>
  );
}

export default App;
