import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

const heading = {
    offscreen: {opacity:0, x:-50},
    onscreen: {opacity:1, x:0, transition: {duration: 1.25, type: 'tween'}},
};
const ctaText = {
    offscreen: {opacity:0, x:-50},
    onscreen: {opacity:1, x:0, transition: {duration: 1.25, type: 'tween', delay: 0.3}},
};
const ctaButton = {
    offscreen: {opacity:0},
    onscreen: {opacity:1,transition: {duration: 1.25, type: 'tween', delay: 0.4}},
};

const CallToAction = () => {
    const [isHovered, setIsHovered] = useState(false);
    
    const handleMouseEnter = () => {
      setIsHovered(true);
    };
  
    const handleMouseLeave = () => {
      setIsHovered(false);
    };
  
    const buttonClass = `py-2 px-6 md:px-10 transition duration-300 rounded-md border-[#f5f5f5] border ${
        isHovered
          ? "bg-[#333] text-[#f5f5f5] border-[#f5f5f5] border"
          : "bg-[#f5f5f5] text-[#333]"
      }`;

    return(
        <div className="max-w-[100%] min-w-[354px] bg-[#333]">
        <div className="grid grid-rows-2 lg:flex pt-16 pb-0 md:pb-16 px-10 lg:px-40 justify-between items-center">
            <div>
                <motion.h1 className="hero-heading text-4xl lg:text-6xl text-[#f5f5f5]"
                    initial = "offscreen"
                    whileInView= "onscreen"
                    variants = {heading}
                    viewport={{ once: true}}
                >
                    Build Your Digital Success.
                </motion.h1>
                <motion.p className="hero-text text-lg text-[#f5f5f5] pt-4"
                    initial = "offscreen"
                    whileInView= "onscreen"
                    variants = {ctaText}
                    viewport={{ once: true}}
                >
                    Every business has a unique story. Let's craft your digital narrative together, tailored to your vision and goals.
                </motion.p>
            </div>
            <motion.div className="flex items-center lg:pl-24"
                initial = "offscreen"
                whileInView= "onscreen"
                variants = {ctaButton}
                viewport={{ once: true}}
            >
                <button
                    className={buttonClass}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    >
                    <a href="/contact" className="button-text uppercase text-base md:text-lg">Let's Connect</a>
                </button>
            </motion.div>
        </div>
    </div>
    )
}
export default CallToAction;