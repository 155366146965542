import React, { useState, useEffect } from 'react';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import { FaArrowRight } from "react-icons/fa6";
import name from '../../utils/Name.svg';
import logolite from '../../utils/Logo-lite.svg';
import { Link as ScrollLink } from 'react-scroll';

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleNav = () => {
    setNav(!nav);
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      if (screenWidth >= 1024 && nav) {
        setNav(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [screenWidth, nav]);

  return (
    <div className="container flex items-center h-20 max-w-[100%] min-w-[354px] px-8">
      {/* Company Name (Logo or Text) - Left */}
      <a href="/" className="flex-shrink-0">
        {screenWidth <= 767 ? (
          <img src={name} alt="companyName" className="w-32" />
        ) : (
          <img src={name} alt="companyName" className="w-40 sm:w-20 md:w-40 lg:w-50 xl:w-60 mt-10 mb-12" />
        )}
      </a>

      {/* Desktop Menu */}
      {screenWidth >= 1024 ? (
        <>
          {/* Tabs (Center) */}
          <div className="flex-grow lg:flex space-x-5 items-center bg-transparent">
            <ul className="flex space-x-5 uppercase flex-1 justify-center">
              <li className="p-5 navbar-text">
                {/* <ScrollLink to="about" smooth={true} duration={1000} className='pointer-cursor'>Home</ScrollLink> */}
                <a href='/'>Home</a>
              </li>
              <li className="p-5 navbar-text">
                {/* <ScrollLink to="about" smooth={true} duration={1000} className='pointer-cursor'>About</ScrollLink> */}
                <a href='/about'>About</a>
              </li>
              <li className="p-5 navbar-text">
                <a href='/services'>Services</a>
              </li>
              <li className="p-5 navbar-text">
                <a href='/process'>Process</a>
              </li>
              <li className="p-5 navbar-text">
                {/* <ScrollLink to="contact" smooth={true} duration={1000} className='pointer-cursor'>Contact</ScrollLink> */}
                <a href='/contact'>Contact</a>
              </li>
            </ul>
          </div>

          {/* Reach Out Button */}
          <div className="reach-out uppercase text-center whitespace-nowrap lg:text-sm bg-transparent">
            <a href='/contact' className='flex items-center pointer-cursor'>
              <span className='mr-2'><FaArrowRight/> </span>
              <span>Start your project</span>
            </a>
          </div>
        </>
      ) : null}

      {/* Mobile Menu */}
      {screenWidth < 1024 ? (
        <div onClick={handleNav} className="block ml-auto mr-4">
          {!nav ? (
            <AiOutlineMenu size={20} className={`text-[#181613] stroke-1`} />
          ) : (
            null
          )}
        </div>
      ) : null}

      {/* Mobile Menu (Dropdown) */}
      <div className={nav ? 'fixed left-0 top-0 max-w-50 w-fit h-full bg-[#1a4d4b] ease-in-out duration-500 z-30' : 'fixed left-[-1000%]'}>
        <ul className="uppercase navbar-text-lite mt-10">
          <li className="p-2 m-2 flex justify-between items-center">
              <img src={logolite} alt="companyLogoLite" className='w-20 sm:w-20 h-12' />
              <div onClick={handleNav} className='block ml-auto mr-8'>
                <AiOutlineClose size={20} className={`text-[#f5f5f5] stroke-1`} />
              </div>
          </li>
          <li className='p-4 m-6'>
            <a href='/'>Home</a>
          </li>
          <li className='p-4 m-6'>
            <a href='/about'>About</a>
          </li>
          <li className='p-4 m-6'>
            <a href='/services'>Services</a>
          </li>
          <li className='p-4 m-6'>
            <a href='/process'>Process</a>
          </li>
          <li className='p-4 m-6'>
            <a href='/contact'>Contact</a>
          </li>
          <li className='p-4 m-6'>
            <div className='mobile-reach-out-container uppercase whitespace-nowrap'>
              <a href="/contact" className='mobile-reach-out-lite flex items-center pointer-cursor text-sm'>
                <span>Start your project</span>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;






