import React, { useEffect, useState, useRef } from "react";
import gsap from "gsap";
import {motion, useScroll, useTransform} from 'framer-motion';
import { Element } from 'react-scroll';


const Hero = () => {

//Parallax Animation using Framer Motion
  const ref = useRef(null);

  const {scrollYProgress} = useScroll ({
    target: ref,
    offset: ["start start", "end start"],
  });

  const textY = useTransform(scrollYProgress, [0,1], ["0%","200%"]);

//Gradient animation using gsap
  const text = ["Envision.", "Execute.", "Excel."];
  const [currentSpanIndex, setCurrentSpanIndex] = useState(0);
  const nextSpanIndex = () => {
    setCurrentSpanIndex((prevIndex) => (prevIndex + 1) % text.length);
  };

  useEffect(() => {
    const intervalId = setInterval(nextSpanIndex, 8000); // Change span every 8 seconds

    return () => {
      clearInterval(intervalId);
    };
  });

  useEffect(() => {
    const tl = gsap.timeline({ repeat: -1 });

    const animateSpan = (index) => {
      tl.to(`.gradient-text:nth-child(${index})`, {
        color: "transparent",
        duration: 1, // Adjust the duration as needed
        ease: "linear",
        onComplete: () => {
          const nextIndex = (index % text.length) + 1;
          animateSpan(nextIndex);
        },
      });

      tl.to(`.gradient-text:nth-child(${index})`, {
        color: "gradient",
        duration: 3, // Adjust the duration as needed
        ease: "linear",
      });

      tl.to(`.gradient-text:nth-child(${index})`, {
        color: "#182826",
        duration: 1, // Adjust the duration as needed
        ease: "linear",
      });
    };

    // Start the animation with "Envision" initially
    animateSpan(1);

    return () => {
      tl.kill();
    };
  }, []);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleResize = () => {
      setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
      // Add a resize event listener when the component mounts
      window.addEventListener("resize", handleResize);

      // Remove the resize event listener when the component unmounts
      return () => {
          window.removeEventListener("resize", handleResize);
      };
  }, []);

  return (
    <Element name="home">
      <div 
          ref={ref}
          className="w-full h-screen min-w-[354px] overflow-hidden relative grid lg:place-items-center"
      >
          {screenWidth >= 1024 ? (
              <div>
                  <motion.div className="text-center mx-10 py-20 lg:py-16 lg:mx-16 lg:mb-24 relative -z-10 items-center"
                      style={{y: textY}}
                  >
                      <h1 className="grid grid-rows-3 lg:block mb-4 hero-heading text-[14vw] sm:text-[12vw] lg:text-[8vw]">
                          {text.map((spanText, index) => (
                          <span
                              key={index}
                              className={`gradient-text ${
                              currentSpanIndex === index ? "" : "black-text"
                              }`}
                          >
                              {spanText}
                              &nbsp;
                          </span>
                          ))}
                      </h1>
                      <p className="mb-8 hero-text text-center text-[3vw] sm:text-[2vw] lg:text-[1.75vw] sm:px-16 xl:px-48 text-[#333]">
                        We are a Toronto-based digital transformation agency dedicated to helping businesses embrace their unique digital potential.
                      </p>
                  </motion.div>
                  <div className = "parallax-image absolute inset-0 z-0"/>
              </div>
          ) : (
              <div>
                  <div className="text-center mx-10 py-16 relative -z-10 items-center">
                      <h1 className="grid grid-rows-3 lg:block my-4 hero-heading text-[8vh] sm:text-[11vh] md:text-[8vh]">
                          {text.map((spanText, index) => (
                          <span
                              key={index}
                              className={`gradient-text ${
                              currentSpanIndex === index ? "" : "black-text"
                              }`}
                          >
                              {spanText}
                              &nbsp;
                          </span>
                          ))}
                      </h1>
                      <p className="mb-2 hero-text text-center text-[#333] text-[4vw] sm:text-[2vw] lg:text-[1.75vw] sm:px-16 xl:px-48">
                        We are a Toronto-based digital transformation agency dedicated to helping businesses embrace their unique digital potential.                      </p>
                  </div>
                  <div className="skyline-image absolute inset-0"/>
              </div>
          )}
      </div>
    </Element>
  );
};

export default Hero;

















