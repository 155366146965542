import React from 'react';
import nameLite from '../../utils/nameLite.svg';
import { motion } from "framer-motion";
import { 
    FaLinkedin,
    FaInstagram
} from "react-icons/fa6";

const Footer = () => {

    const footer = {
        offscreen: {opacity:0},
        onscreen: {opacity:1, transition: {duration: 1.25, type: 'tween'}},
    };

    return (
        <div className="max-w-[100%] min-w-[354px] px-10 py-10 md:px-40 bg-[#181613]">
            <div className="lg:flex items-center">
                <motion.div
                    initial = "offscreen"
                    whileInView= "onscreen"
                    variants = {footer}
                    viewport={{ once: true}}
                >
                    <a href="/" className="flex-shrink-0">
                        <img src={nameLite} alt="Enviion Toronto Web Development and Digital Consulting Agency" className="w-32 lg:w-48 mx-5" />
                    </a>
                </motion.div>
                <div className='block lg:hidden'>
                    <motion.h1 className='hero-heading uppercase text-sm lg:text-base text-[#f5f5f5] px-5 pt-3'
                        initial = "offscreen"
                        whileInView= "onscreen"
                        variants = {footer}
                        viewport={{ once: true}}
                    >
                        Envision. &nbsp; Execute. &nbsp; Excel.
                    </motion.h1>
                    <motion.p className='hero-text text-[#f5f5f5] px-5 py-3'
                        initial = "offscreen"
                        whileInView= "onscreen"
                        variants = {footer}
                        viewport={{ once: true}}
                    >
                        <span className='text-sm'>
                            Transforming your creative ideas into interactive realities, we navigate the digital realm with a blend of elegance and expertise.
                        </span>
                        <span className='flex space-x-4 text-2xl py-4'>
                            <a href='https://www.instagram.com/enviion/' target='_blank'>
                                <FaInstagram/>
                            </a>
                            <a href='https://www.linkedin.com/company/enviion/' target='_blank'>
                                <FaLinkedin/>
                            </a>
                        </span>
                    </motion.p>
                </div>
                <motion.div className="hidden lg:block lg:pl-32"
                    initial = "offscreen"
                    whileInView= "onscreen"
                    variants = {footer}
                    viewport={{ once: true}}
                >
                    <ul className="flex space-x-5 uppercase items-center">
                        <li className="p-5 navbar-text-lite">
                            <a href="/about">About</a>
                        </li>
                        <li className="p-5 navbar-text-lite">
                            <a href='/services'>Services</a>
                        </li>
                        <li className="p-5 navbar-text-lite">
                            <a href='/process'>Process</a>
                        </li>
                        <li className="p-5 navbar-text-lite">
                            <a href='/contact'>Contact</a>
                        </li>
                    </ul>
                </motion.div>
            </div>
            <hr class="h-px mb-8 bg-gray-200 border-0 dark:bg-gray-700"/>

            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 pb-5'>
                <div className='hidden lg:block'>
                    <motion.h1 className='hero-heading uppercase text-sm lg:text-base text-[#f5f5f5] px-5 pt-3'
                        initial = "offscreen"
                        whileInView= "onscreen"
                        variants = {footer}
                        viewport={{ once: true}}
                    >
                        Envision. &nbsp; Execute. &nbsp; Excel.
                    </motion.h1>
                    <motion.p className='hero-text text-[#f5f5f5] px-5 py-3'
                        initial = "offscreen"
                        whileInView= "onscreen"
                        variants = {footer}
                        viewport={{ once: true}}
                    >
                        <span className='text-base'>
                            Transforming your creative ideas into interactive realities, we navigate the digital realm with a blend of elegance and expertise.
                        </span>
                        <span className='flex space-x-4 text-4xl py-4'>
                            <a href='https://www.instagram.com/enviion/' target='_blank'>
                                <FaInstagram/>
                            </a>
                            <a href='https://www.linkedin.com/company/enviion/' target='_blank'>
                                <FaLinkedin/>
                            </a>
                        </span>
                    </motion.p>
                </div>
                <motion.div className='sm:pl-14'
                    initial = "offscreen"
                    whileInView= "onscreen"
                    variants = {footer}
                    viewport={{ once: true}}
                >
                    <h1 className='px-5 py-3 footer-heading text-[#f5f5f5] uppercase'>Services</h1>
                    <ul className="uppercase footer-text">
                        <li className="px-5 pb-2">
                            <a href='/services'>Digital Strategy Development</a>
                        </li>
                        <li className="px-5 pb-2">
                            <a href='/services'>Business Process Automation</a>
                        </li>
                        <li className="px-5 pb-2">
                            <a href='/services'>Digital Marketing</a>
                        </li>
                        <li className="px-5 pb-2">
                            <a href='/services'>Web Development</a>
                        </li>
                        <li className="px-5 pb-2">
                            <a href='/services'>App Development</a>
                        </li>
                        <li className="px-5 pb-2">
                            <a href='/services'>UI/UX Design</a>
                        </li>
                        <li className="px-5 pb-2">
                            <a href='/services'>Website Maintenance</a>
                        </li>
                        <li className="px-5 pb-2">
                            <a href='/services'>Data Analytics</a>
                        </li>
                        <li className="px-5 pb-2">
                            <a href='/services'>CMS Integration</a>
                        </li>
                    </ul>
                </motion.div>
                <motion.div className='sm:pl-14'
                    initial = "offscreen"
                    whileInView= "onscreen"
                    variants = {footer}
                    viewport={{ once: true}}
                >
                    <h1 className='px-5 py-3 footer-heading text-[#f5f5f5] uppercase'>Process</h1>
                    <ul className="uppercase footer-text">
                        <li className="px-5 pb-2">
                            <a href='/process'>Onboarding</a>
                        </li>
                        <li className="px-5 pb-2">
                            <a href='/process'>Strategy</a>
                        </li>
                        <li className="px-5 pb-2">
                            <a href='/process'>Design</a>
                        </li>
                        <li className="px-5 pb-2">
                            <a href='/process'>Development</a>
                        </li>
                        <li className="px-5 pb-2">
                            <a href='/process'>Deployment</a>
                        </li>
                        <li className="px-5 pb-2">
                            <a href='/process'>Maintenance</a>
                        </li>
                    </ul>
                </motion.div>
                <motion.div className='sm:pl-14'
                    initial = "offscreen"
                    whileInView= "onscreen"
                    variants = {footer}
                    viewport={{ once: true}}
                >
                    <h1 className='px-5 py-3 footer-heading text-[#f5f5f5] uppercase'>Enviion</h1>
                    <ul className="uppercase footer-text">
                        <li className="px-5 pb-2">
                            <a href="/">Home</a>
                        </li>
                        <li className="px-5 pb-2">
                            <a href="/about">About</a>
                        </li>
                        <li className="px-5 pb-2">
                            <a href='/contact'>Contact</a>
                        </li>
                    </ul>
                </motion.div>
            </div>
            <motion.p className='footer-text px-5 pt-5'
                initial = "offscreen"
                whileInView= "onscreen"
                variants = {footer}
                viewport={{ once: true}}
            >
                admin@enviion.com
            </motion.p>
            <motion.p className='footer-text p-5'
                initial = "offscreen"
                whileInView= "onscreen"
                variants = {footer}
                viewport={{ once: true}}
            >
                &copy; 2023 ENVIION. All Rights Reserved.
            </motion.p>
        </div>
    );
}

export default Footer;