import Navbar from '../common/Navbar';
import ContactUs from './ContactUs';
import Footer from '../common/Footer';

function Contact() {
    return (
      <>
        <Navbar />
        <ContactUs />
        <Footer/>
      </>
    );
  }


export default Contact;