// ContactForm.js
import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { 
  FaLinkedin,
  FaInstagram
} from "react-icons/fa6";

function ContactForm() {
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleSubmit = async (e) => {
      e.preventDefault();

    try {
        const { from_name, from_company, from_email, from_phone, message } = e.target;
    
        const emailContent = `
            
            Name: ${from_name.value}
            Company: ${from_company.value}
            Email: ${from_email.value}
            Phone: ${from_phone.value}
            Message: ${message.value}

            `;
        
        const templateParams = {
            to_name: 'Enviion', // Replace with your business's name
            message: emailContent,
        };

      await emailjs.send(
        'service_je34w9p', // Service ID
        'template_q7r74ap', // Template ID
        templateParams,
        'pH_glApAn4dz_SU4B' // API Key
      );

      setIsSubmitted(true);
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  return (
    <div>
      {isSubmitted ? (
        <p className='hero-text text-lg text-[#f5f5f5] pt-4 pb-14 px-20 text-center lg:text-end'>
            High-fives and virtual confetti to you! 🎉 
            Your message has reached us loud and clear. 
            We're excited to dive into your project and cook up some digital magic. 
            Stay tuned for our 'e-mazing' response! 😄
        </p>
      ) : (
        <section className="bg-white rounded-xl">
            <div className="py-8 lg:py-16 px-8 mx-auto max-w-screen-md">
                <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 ">Contact Us</h2>
                <p className="mb-8 lg:mb-16 font-light text-center">
                    <span className='text-gray-500 sm:text-xl'>
                      Reach out to us now, and let's discuss how we can bring your vision to life. 
                      Your digital journey begins here!
                    </span>
                    <span className='flex space-x-4 text-4xl py-4 text-gray-900 justify-center'>
                        <a href='https://www.instagram.com/enviion/' target='_blank'>
                            <FaInstagram/>
                        </a>
                        <a href='https://www.linkedin.com/company/enviion/' target='_blank'>
                            <FaLinkedin/>
                        </a>
                    </span>
                </p>
                <form onSubmit={handleSubmit} className="space-y-8">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                    <label htmlFor="from_name" className="block mb-2 text-sm font-medium text-gray-900 ">Your Name</label>
                    <input type="text" id="from_name" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 " placeholder="Your Name" required />
                    </div>
                    <div>
                    <label htmlFor="from_company" className="block mb-2 text-sm font-medium text-gray-900 ">Your Company</label>
                    <input type="text" id="from_company" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 " placeholder="Your Company" required />
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                    <label htmlFor="from_email" className="block mb-2 text-sm font-medium text-gray-900 ">Your Email</label>
                    <input type="email" id="from_email" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 " placeholder="Your Email" required />
                    </div>
                    <div>
                    <label htmlFor="from_phone" className="block mb-2 text-sm font-medium text-gray-900 ">Your Phone</label>
                    <input type="tel" id="from_phone" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 " placeholder="Your Phone" required />
                    </div>
                </div>
                <div>
                    <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 ">Your Message</label>
                    <textarea id="message" rows="6" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 " placeholder="Describe Your Project..."></textarea>
                </div>
                <button type="submit" className="py-2 px-4 button-text uppercase text-sm md:text-base border-2 border-[#193b38] bg-[#193b38] text-[#f5f5f5] rounded-md hover:bg-[#f5f5f5] hover:text-[#193b38]">Send message</button>
                </form>
            </div>
        </section>
      )}
    </div>
  );
}

export default ContactForm;