import React from "react";
import { Link as ScrollLink } from 'react-scroll';
import { FaArrowRight } from "react-icons/fa6";
import { motion } from "framer-motion";
import Marquee from "react-fast-marquee";

const animation = {
    offscreen: {opacity:0},
    onscreen: {opacity:1, transition: {duration: 1.25, type: 'tween'}},
};

const Landing = () => {
    return ( 
        <div className="max-w-[100%] min-w-[354px] h-fit overflow-hidden mb-10 lg:mb-12 py-10">
            <div className="px-10 md:px-16 pt-5 md:pt-10">
                <motion.div className="hero-heading gradient-text text-6xl md:text-8xl lg:text-[11vw] flex justify-center pb-5 md:pb-10"
                    initial = "offscreen"
                    whileInView= "onscreen"
                    variants = {animation}
                    viewport={{ once: true}}
                >
                    <h1>Services</h1>
                </motion.div>
                <div className="hero-text text-base md:text-lg lg:text-xl md:px-10 flex justify-center text-center">
                    <motion.p className="pb-4 text-[#333]"
                        initial = "offscreen"
                        whileInView= "onscreen"
                        variants = {animation}
                        viewport={{ once: true}}
                    >
                        We help elevate your digital presence with our comprehensive suite of web development, design, maintenance and digital consulting services. 
                    </motion.p>
                </div>
                <div className="pb-10 lg:pb-auto pt-2 px-10 flex justify-center">
                    <motion.div className="reach-out uppercase text-center whitespace-nowrap text-sm md:text-base"
                        initial = "offscreen"
                        whileInView= "onscreen"
                        variants = {animation}
                        viewport={{ once: true}}
                    >
                        <a href="/contact" className='flex items-center pointer-cursor'>
                            <span className='mr-2'><FaArrowRight/></span>
                            <span>Get Things Rolling</span>
                        </a>
                    </motion.div>
                </div>
            </div>
            <div className="pt-8 md:pt-14 ">
                <Marquee speed={100} delay={0.5} direction={'left'}>
                    <h1 className="hero-heading text-[#238481] text-xl md:text-3xl mr-16 uppercase">Digital Strategy Consulting</h1>
                    <h1 className="hero-heading text-[#238481] text-xl md:text-3xl mr-16 uppercase">Business Process Automation</h1>
                    <h1 className="hero-heading text-[#238481] text-xl md:text-3xl mr-16 uppercase">Data Analytics</h1>
                    <h1 className="hero-heading text-[#238481] text-xl md:text-3xl mr-16 uppercase">Website Development</h1>
                    <h1 className="hero-heading text-[#238481] text-xl md:text-3xl mr-16 uppercase">Mobile App Development</h1>
                    <h1 className="hero-heading text-[#238481] text-xl md:text-3xl mr-16 uppercase">UI/UX Design</h1>
                    <h1 className="hero-heading text-[#238481] text-xl md:text-3xl mr-16 uppercase">Digital Marketing</h1>
                    <h1 className="hero-heading text-[#238481] text-xl md:text-3xl mr-16 uppercase">Web Maintenance</h1>
                    <h1 className="hero-heading text-[#238481] text-xl md:text-3xl mr-16 uppercase">Search Engine Optimization</h1>
                    <h1 className="hero-heading text-[#238481] text-xl md:text-3xl mr-16 uppercase">Content Management Systems</h1>
                </Marquee>
            </div>
        </div>
    );
}

export default Landing;