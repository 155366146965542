import React from "react";
import { motion } from "framer-motion";

const heading = {
    offscreen: {opacity:0},
    onscreen: {opacity:1, transition: {duration: 1.25, type: 'tween'}},
};

const AboutUs = () => {
    return (
        <div className="max-w-[100%] min-w-[354px] h-fit overflow-hidden px-6 mt-28 lg:my-32">
            <div className="grid grid-flow-row px-10 justify-items-center">
                <motion.h1 className="hero-heading text-xl md:text-2xl py-4 text-[#181613]"
                    initial = "offscreen"
                    whileInView= "onscreen"
                    variants = {heading}
                    viewport={{ once: true}}
                >
                    Our Mission
                </motion.h1>
                <motion.p className="quote-text text-2xl md:text-3xl text-[#238480] pb-10 text-center"
                    initial = "offscreen"
                    whileInView= "onscreen"
                    variants = {heading}
                    viewport={{ once: true}}
                >
                    "Creating Digital Masterpieces Tailored to Your Uniqueness."
                </motion.p>
                <motion.p className="hero-text text-lg md:text-xl text-[#333] pb-4 lg:px-24 text-center"
                    initial = "offscreen"
                    whileInView= "onscreen"
                    variants = {heading}
                    viewport={{ once: true}}
                >
                    We believe that every business deserves a digital presence as unique as itself. 
                    That's why we take the time to get to know you, your vision, and your aspirations. 
                    We're not in the business of mass-producing software; we're here to craft digital masterpieces tailored to your uniqueness.
                </motion.p>
            </div>
        </div>
    );
}

export default AboutUs;

