import React from "react";
import { Link as ScrollLink } from 'react-scroll';
import { FaArrowRight } from "react-icons/fa6";
import { motion } from "framer-motion";

const animation = {
    offscreen: {opacity:0},
    onscreen: {opacity:1, transition: {duration: 1.25, type: 'tween'}},
};

const Landing = () => {
    return ( 
        <div className="max-w-[100%] min-w-[354px] h-fit overflow-hidden pt-10">
            <div className="px-10 md:px-16 pt-5 md:pt-10">
                <motion.h1 className="hero-heading text-2xl md:text-4xl pt-4 pb-10 text-[#181613] text-center"
                    initial = "offscreen"
                    whileInView= "onscreen"
                    variants = {animation}
                    viewport={{ once: true}}
                >
                    Our Process
                </motion.h1>
                <motion.div className="hero-heading gradient-text text-4xl md:text-8xl lg:text-[7.5vw] flex justify-center pb-5 md:pb-10 text-center"
                    initial = "offscreen"
                    whileInView= "onscreen"
                    variants = {animation}
                    viewport={{ once: true}}
                >
                    <h1>Your Journey Begins Here</h1>
                </motion.div>
                <div className="hero-text text-lg lg:text-2xl md:px-10 flex justify-center text-center">
                    <motion.p className="pb-4 text-[#333]"
                        initial = "offscreen"
                        whileInView= "onscreen"
                        variants = {animation}
                        viewport={{ once: true}}
                    >
                        Embracing innovation, our process is geared towards helping businesses succeed by building their one-of-a-kind digital presence. 
                    </motion.p>
                </div>
                <div className="pb-10 lg:pb-auto pt-2 px-10 flex justify-center">
                    <motion.div className="reach-out uppercase text-center whitespace-nowrap text-sm md:text-base"
                        initial = "offscreen"
                        whileInView= "onscreen"
                        variants = {animation}
                        viewport={{ once: true}}
                    >
                        <a href="/contact" className='flex items-center pointer-cursor'>
                            <span className='mr-2'><FaArrowRight/></span>
                            <span>Begin Your Journey</span>
                        </a>
                    </motion.div>
                </div>
            </div>
        </div>
    );
}

export default Landing;