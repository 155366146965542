import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

const heading = {
    offscreen: {opacity:0},
    onscreen: {opacity:1, transition: {duration: 1.25, type: 'tween'}},
};
const headingNumber = {
    offscreen: {opacity:0},
    onscreen: {opacity:0.3, transition: {duration: 1.25, type: 'tween'}},
}
const processText = {
    offscreen: {opacity:0, y:50},
    onscreen: {opacity:1, y:0, transition: {duration: 1.25, type: 'tween', delay: 0.3}},
};

const Journey = () => {
    return (
        <div className="max-w-[100%] min-w-[354px] h-fit overflow-hidden mb-10 lg:mb-12 py-10">
            <div className="grid lg:grid-cols-2 gap-4 px-10">
                    
                            <div className="grid-flow-row py-10">
                                <div className="place-items-center">
                                    <motion.h1 className="process-number text-7xl sm:text-9xl"
                                        initial = "offscreen"
                                        whileInView= "onscreen"
                                        variants = {headingNumber}
                                        viewport={{ once: true}}
                                    >
                                        01
                                    </motion.h1>
                                    <motion.h1 className="hero-heading text-4xl sm:text-5xl text-[#181613] relative -mt-12 sm:-mt-20 pl-2"
                                        initial = "offscreen"
                                        whileInView= "onscreen"
                                        variants = {heading}
                                        viewport={{ once: true}}
                                    >
                                        Onboarding
                                    </motion.h1>
                                </div>
                                <motion.p className="text-[#333] px-10 pt-10 items-center"
                                    initial = "offscreen"
                                    whileInView= "onscreen"
                                    variants = {processText}
                                    viewport={{ once: true}}
                                >
                                    <span className="quote-text text-3xl text-[#238481]">
                                        Understanding Your Vision 
                                        <br/>
                                        <br/>
                                    </span>
                                    <span className="hero-text text-lg">
                                        Our journey begins with Onboarding, where we dive deep into your business, goals, and aspirations. 
                                        We engage in intensive discussions about your business, focusing on key areas like digital strategy consulting and business process automation. 
                                        By delving into your vision, we explore the potential of custom software development and integration of diverse digital tools to revolutionize your business operations.
                                    </span>
                                </motion.p>
                            </div>

                            <div className="grid-flow-row py-10">
                                <div className="place-items-center">
                                    <motion.h1 className="process-number text-7xl sm:text-9xl"
                                        initial = "offscreen"
                                        whileInView= "onscreen"
                                        variants = {headingNumber}
                                        viewport={{ once: true}}
                                    >
                                        02
                                    </motion.h1>
                                    <motion.h1 className="hero-heading text-4xl sm:text-5xl text-[#181613] relative -mt-12 sm:-mt-20 pl-2"
                                        initial = "offscreen"
                                        whileInView= "onscreen"
                                        variants = {heading}
                                        viewport={{ once: true}}
                                    >
                                        Strategy
                                    </motion.h1>
                                </div>
                                <motion.p className="text-[#333] px-10 pt-10 items-center"
                                    initial = "offscreen"
                                    whileInView= "onscreen"
                                    variants = {processText}
                                    viewport={{ once: true}}
                                >
                                    <span className="quote-text text-3xl text-[#238481]">
                                        Creating Your Unique Game Plan
                                        <br/>
                                        <br/>
                                    </span>
                                    <span className="hero-text text-lg">
                                    In this phase, we craft a comprehensive digital strategy that means business. 
                                    We dig into market trends, analyze competitors, decipher audience insights, and understand your business requirements. 
                                    This is where we set the course for your business' digital transformation, ensuring it aligns with your goals and stands out in the digital landscape.
                                    Data-driven insights guide our strategy, ensuring a comprehensive plan.
                                    </span>
                                </motion.p>
                            </div>

                            <div className="grid-flow-row py-10">
                                <div className="place-items-center">
                                    <motion.h1 className="process-number text-7xl sm:text-9xl"
                                        initial = "offscreen"
                                        whileInView= "onscreen"
                                        variants = {headingNumber}
                                        viewport={{ once: true}}
                                    >
                                        03
                                    </motion.h1>
                                    <motion.h1 className="hero-heading text-4xl sm:text-5xl text-[#181613] relative -mt-12 sm:-mt-20 pl-2"
                                        initial = "offscreen"
                                        whileInView= "onscreen"
                                        variants = {heading}
                                        viewport={{ once: true}}
                                    >
                                        Design
                                    </motion.h1>
                                </div>
                                <motion.p className="text-[#333] px-10 pt-10 items-center"
                                    initial = "offscreen"
                                    whileInView= "onscreen"
                                    variants = {processText}
                                    viewport={{ once: true}}
                                >
                                    <span className="quote-text text-3xl text-[#238481]">
                                        Creative Expression Of Your Business
                                        <br/>
                                        <br/>
                                    </span>
                                    <span className="hero-text text-lg">
                                        Our Design phase is where creativity meets functionality. 
                                        We craft detailed mock-ups of the user interface, meticulously mapping out every element. 
                                        This ensures not just a visually appealing product but also a functionally robust software solution. 
                                        We delve into constructing the project architecture, ensuring a robust foundation for your digital transformation journey.
                                    </span>
                                </motion.p>
                            </div>

                            <div className="grid-flow-row py-10">
                                <div className="place-items-center">
                                    <motion.h1 className="process-number text-7xl sm:text-9xl"
                                        initial = "offscreen"
                                        whileInView= "onscreen"
                                        variants = {headingNumber}
                                        viewport={{ once: true}}
                                    >
                                        04
                                    </motion.h1>
                                    <motion.h1 className="hero-heading text-4xl sm:text-5xl text-[#181613] relative -mt-12 sm:-mt-20 pl-2"
                                        initial = "offscreen"
                                        whileInView= "onscreen"
                                        variants = {heading}
                                        viewport={{ once: true}}
                                    >
                                        Development 
                                    </motion.h1>
                                </div>
                                <motion.p className="text-[#333] px-10 pt-10 items-center"
                                    initial = "offscreen"
                                    whileInView= "onscreen"
                                    variants = {processText}
                                    viewport={{ once: true}}
                                >
                                    <span className="quote-text text-3xl text-[#238481]">
                                        Building With Precision
                                        <br/>
                                        <br/>
                                    </span>
                                    <span className="hero-text text-lg">
                                        When it comes to Development & Testing, it's all about precision. 
                                        We meticulously build your solution with top-notch technologies and reliable Software Development practices, emphasizing flexibility and scalability.
                                        Rigorous testing guarantees everything works seamlessly and continuous iterations empower us to quickly adapt, ensuring the evolving software aligns seamlessly with your expectations.
                                    </span>
                                </motion.p>
                            </div>

                            <div className="grid-flow-row py-10">
                                <div className="place-items-center">
                                    <motion.h1 className="process-number text-7xl sm:text-9xl"
                                        initial = "offscreen"
                                        whileInView= "onscreen"
                                        variants = {headingNumber}
                                        viewport={{ once: true}}
                                    >
                                        05
                                    </motion.h1>
                                    <motion.h1 className="hero-heading text-4xl sm:text-5xl text-[#181613] relative -mt-12 sm:-mt-20 pl-2"
                                        initial = "offscreen"
                                        whileInView= "onscreen"
                                        variants = {heading}
                                        viewport={{ once: true}}
                                    >
                                        Deployment
                                    </motion.h1>
                                </div>
                                <motion.p className="text-[#333] px-10 pt-10 items-center"
                                    initial = "offscreen"
                                    whileInView= "onscreen"
                                    variants = {processText}
                                    viewport={{ once: true}}
                                >
                                    <span className="quote-text text-3xl text-[#238481]">
                                        Launching Excellence
                                        <br/>
                                        <br/>
                                    </span>
                                    <span className="hero-text text-lg">
                                        Deployment is where your digital presence takes center stage. 
                                        We expertly integrate your vision into reality, ensuring seamless product integration across your organization. 
                                        Whether it's a web app or process automation software, our meticulous approach ensures every component aligns seamlessly with your business. 
                                        With Enviion by your side, embrace innovation with confidence, empowering your team to thrive in the new digital landscape.
                                    </span>
                                </motion.p>
                            </div>

                            <div className="grid-flow-row py-10">
                                <div className="place-items-center">
                                    <motion.h1 className="process-number text-7xl sm:text-9xl"
                                        initial = "offscreen"
                                        whileInView= "onscreen"
                                        variants = {headingNumber}
                                        viewport={{ once: true}}
                                    >
                                        06
                                    </motion.h1>
                                    <motion.h1 className="hero-heading text-4xl sm:text-5xl text-[#181613] relative -mt-12 sm:-mt-20 pl-2"
                                        initial = "offscreen"
                                        whileInView= "onscreen"
                                        variants = {heading}
                                        viewport={{ once: true}}
                                    >
                                        Maintenance
                                    </motion.h1>
                                </div>
                                <motion.p className="text-[#333] px-10 pt-10 items-center"
                                    initial = "offscreen"
                                    whileInView= "onscreen"
                                    variants = {processText}
                                    viewport={{ once: true}}
                                >
                                    <span className="quote-text text-3xl text-[#238481]">
                                        Nurturing Ongoing Success
                                        <br/>
                                        <br/>
                                    </span>
                                    <span className="hero-text text-lg">
                                    During the Maintenance phase, Enviion employs data analysis to guide our iterations. 
                                    Every software enhancement is meticulously tailored based on user data, ensuring precise alignment with evolving business needs. 
                                    With insights from users at the core, our approach guarantees targeted and efficient software modifications, enabling your digital solution to continuously adapt and excel within your business ecosystem.
                                    </span>
                                </motion.p>
                            </div>
                </div>
        </div>
    );
}

export default Journey;