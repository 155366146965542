import React from "react";
import { motion } from "framer-motion";
import { FaArrowRight } from "react-icons/fa6"; 
import { Element, Link } from "react-scroll";

const heading = {
    offscreen: {opacity:0},
    onscreen: {opacity:1, transition: {duration: 1.25, type: 'tween'}},
};
const headingNumber = {
    offscreen: {opacity:0},
    onscreen: {opacity:0.3, transition: {duration: 1.25, type: 'tween'}},
}
const processText = {
    offscreen: {opacity:0, y:50},
    onscreen: {opacity:1, y:0, transition: {duration: 1.25, type: 'tween', delay: 0.3}},
};

const Process = () => {
    return ( 
        <Element name = "process">
            <section className="processes-section max-w-[100%] min-w-[340px] mb-28 lg:mb-32 px-6">
                <div className="grid grid-flow-row">
                    <motion.h1 className="hero-heading text-6xl md:text-7xl px-10 py-4 text-[#181613] flex justify-center text-center"
                        initial = "offscreen"
                        whileInView= "onscreen"
                        variants = {heading}
                        viewport={{ once: true}}
                    >
                        Our Process
                    </motion.h1>
                    <motion.p className="quote-text text-2xl text-[#238481] px-10 pb-4 flex justify-center text-center"
                        initial = "offscreen"
                        whileInView= "onscreen"
                        variants = {processText}
                        viewport={{ once: true}}
                    >
                        Your success is our mission, and every step we take is a stride toward your business's digital triumph.
                    </motion.p>
                    <motion.p className="hero-text text-lg text-[#333] px-10 pb-4 flex justify-center text-center"
                        initial = "offscreen"
                        whileInView= "onscreen"
                        variants = {processText}
                        viewport={{ once: true}}
                    >
                        Embracing innovation, our process is geared towards helping businesses succeed by building their one-of-a-kind digital presence. 
                        From the initial brainstorming sessions, where your vision takes shape, to the intricate development phase, and finally, the launch that marks your digital transformation – each step is infused with creativity, technical expertise, and a profound understanding of your brand. 
                    </motion.p>
                    <div className="pb-10 lg:pb-auto pt-2 px-10 flex justify-center">
                            <motion.div className="reach-out uppercase text-center whitespace-nowrap text-sm md:text-base"
                                initial = "offscreen"
                                whileInView= "onscreen"
                                variants = {processText}
                                viewport={{ once: true}}
                            >
                                <a href="/process" className='flex items-center pointer-cursor'>
                                    <span className='mr-2'><FaArrowRight/></span>
                                    <span>Learn More</span>
                                </a>
                            </motion.div>
                        </div>
                </div>
            </section>
        </Element>
    );
}

export default Process;
