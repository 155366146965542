import Navbar from '../common/Navbar';
import AboutLanding from './AboutLanding';
import AboutUs from './AboutUs';
import CompanyValues from './CompanyValues';
import CallToAction from './CallToAction';
import Footer from '../common/Footer';

function About() {
    return (
      <>
        <Navbar />
        <AboutLanding />
        <AboutUs />
        <CompanyValues />
        <CallToAction />
        <Footer/>
      </>
    );
  }


export default About;