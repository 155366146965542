import React from "react";
import { motion } from "framer-motion";
import { FaArrowRight } from "react-icons/fa6";
import { Element, Link } from "react-scroll";


const heading = {
    offscreen: {opacity:0},
    onscreen: {opacity:1, transition: {duration: 1.25, type: 'tween'}},
};

const ethosText = {
    offscreen: {opacity:0},
    onscreen: {opacity:1, transition: {duration: 1.25, type: 'tween', delay: 0.3}},
};

const ethosCTA = {
    offscreen: {opacity:0},
    onscreen: {opacity:1, transition: {duration: 1.25, type: 'tween', delay: 0.4}},
};

const Ethos = () => {

    return (
        <Element name="about">
            <div className="max-w-[100%] min-w-[354px] -mt-1 px-6 bg-[#181613]">
                <div className="grid grid-flow-row">
                    <div className="hero-heading px-10 pt-14 text-4xl md:text-6xl lg:text-7xl xl:9xl">
                        <motion.h2 className="flex text-[#f5f5f5]"
                            initial = "offscreen"
                            whileInView= "onscreen"
                            variants = {heading}
                            viewport={{ once: true}}
                        >
                            Building Your Unique Digital Identity.
                        </motion.h2>
                    </div>
                    <div className="flex flex-col items-start md:my-auto px-10 py-14">
                        <div className="block md:grid grid-cols-3">
                            <motion.p className="md:col-span-2 hero-text text-lg lg:text-xl text-[#f5f5f5]"
                                initial = "offscreen"
                                whileInView= "onscreen"
                                variants = {ethosText}
                                viewport={{ once: true}}
                            >
                                {/* Display the text data */}
                                At Enviion, these three words are the very foundation of our company ethos: Envision, Execute, Excel. <br/>
                                We work with you to envision your unique digital aspirations. 
                                With precision and expertise through our software development and digital consulting services, we execute your ideas and turn them into reality. 
                                Our ultimate goal is to help your business excel in the digital landscape, driving growth, engagement, and success. 
                                With Enviion by your side, experience streamlined operations and accelerated growth through strategic digital initiatives. 
                            </motion.p>
                        </div>
                        <motion.div className="text-sm md:text-base more-about uppercase whitespace-nowrap pt-14"
                            initial = "offscreen"
                            whileInView= "onscreen"
                            variants = {ethosCTA}
                            viewport={{ once: true}}
                        >
                            <Link to="contact" smooth={true} duration={1000} className='flex items-center pointer-cursor'>
                                <span className='mr-2'><FaArrowRight/> </span>
                                <span>Get Started Today</span>
                            </Link>
                        </motion.div>
                    </div>
                </div>
            </div>
        </Element>
    );
}

export default Ethos;

