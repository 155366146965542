import React from "react";
import { motion } from "framer-motion";
import { FaArrowRight } from "react-icons/fa6"; 
import { Element, Link } from "react-scroll";

const heading = {
    offscreen: {opacity:0},
    onscreen: {opacity:1, transition: {duration: 1.25, type: 'tween'}},
};
const servicesText = {
    offscreen: {opacity:0, y:50},
    onscreen: {opacity:1, y:0, transition: {duration: 1.25, type: 'tween', delay: 0.3}},
};

const servicesCTA = {
    offscreen: {opacity:0, y:50},
    onscreen: {opacity:1, y:0 ,transition: {duration: 1.25, type: 'tween', delay: 0.4}},
};

const HomeService = () => {
    return (
        <Element name = "service">
            <section className="services-section max-w-[100%] min-w-[340px] my-28 lg:my-32 px-6">
                <div className="grid lg:grid-cols-3 gap-4">
                    <div className="flex flex-col justify-center">
                        <motion.div className=""
                            initial = "offscreen"
                            whileInView= "onscreen"
                            variants = {heading}
                            viewport={{ once: true}}
                        >
                            <h1 className="hero-heading text-6xl md:text-7xl px-10 py-4 text-[#181613]">Services</h1>
                        </motion.div>
                        <motion.div className="flex"
                            initial = "offscreen"
                            whileInView= "onscreen"
                            variants = {servicesText}
                            viewport={{ once: true}}
                        >
                            <p className="hero-text text-lg text-[#333] px-10 pb-4">
                                Enviion offers a holistic suite of digital solutions to fuel your business success. 
                                We're more than just experts, we're your dedicated partners in digital excellence.
                            </p>
                        </motion.div>
                        <div className="pb-10 lg:pb-auto pt-2 px-10">
                            <motion.div className="reach-out uppercase text-center whitespace-nowrap text-sm md:text-base"
                                initial = "offscreen"
                                whileInView= "onscreen"
                                variants = {servicesCTA}
                                viewport={{ once: true}}
                            >
                                <a href="/services" className='flex items-center pointer-cursor'>
                                    <span className='mr-2'><FaArrowRight/> </span>
                                    <span>Explore Options</span>
                                </a>
                            </motion.div>
                        </div>
                    </div>
                    <div className="grid sm:grid-cols-2 lg:grid-cols-1 items-center justify-center sm:justify-normal">
                        <div className="px-10 lg:px-auto">
                            <motion.h2 className="hero-heading text-[#181613] text-4xl py-4"
                                initial = "offscreen"
                                whileInView= "onscreen"
                                variants = {heading}
                                viewport={{ once: true}}
                            >
                                Digital Strategy
                            </motion.h2>
                            <motion.p className="hero-text text-lg text-[#333] pb-4"
                                initial = "offscreen"
                                whileInView= "onscreen"
                                variants = {servicesText}
                                viewport={{ once: true}}
                            >
                                Seamlessly integrate technology into your business DNA. 
                                Our tailored strategies drive digital transformations, optimize business processes and automate workflows.
                            </motion.p>
                        </div>
                        <div className="px-10 lg:px-auto">
                            <motion.h2 className="hero-heading text-[#181613] text-4xl py-4"
                                initial = "offscreen"
                                whileInView= "onscreen"
                                variants = {heading}
                                viewport={{ once: true}}
                            >
                                Development & Integration 
                            </motion.h2>
                            <motion.p className="hero-text text-lg text-[#333] pb-4"
                                initial = "offscreen"
                                whileInView= "onscreen"
                                variants = {servicesText}
                                viewport={{ once: true}}
                            >
                                Transform your visions into powerful solutions. 
                                With custom software, user-friendly apps, responsive websites, and seamless integrations, we empower your business to thrive, innovate, and excel in the digital world.
                            </motion.p>
                        </div>
                    </div>
                    <div className="grid sm:grid-cols-2 lg:grid-cols-1 items-center justify-center sm:justify-normal">
                        <div className="px-10 lg:px-auto"> 
                            <motion.h2 className="hero-heading text-[#181613] text-4xl py-4"
                                initial = "offscreen"
                                whileInView= "onscreen"
                                variants = {heading}
                                viewport={{ once: true}}
                            >
                                Data Analytics
                            </motion.h2>
                            <motion.p className="hero-text text-lg text-[#333] pb-4"
                                initial = "offscreen"
                                whileInView= "onscreen"
                                variants = {servicesText}
                                viewport={{ once: true}}
                            >
                                Empower your decisions with data-driven insights. 
                                We delve deep, extract meaningful insights, refine analytics strategies, and provide clear pathways for the growth of your business.
                            </motion.p>
                        </div>
                        <div className="px-10 lg:px-auto"> 
                            <motion.h2 className="hero-heading text-[#181613] text-4xl py-4"
                                initial = "offscreen"
                                whileInView= "onscreen"
                                variants = {heading}
                                viewport={{ once: true}}
                            >
                                Digital Marketing 
                            </motion.h2>
                            <motion.p className="hero-text text-lg text-[#333] pb-4"
                                initial = "offscreen"
                                whileInView= "onscreen"
                                variants = {servicesText}
                                viewport={{ once: true}}
                            >
                                Amplify your brand's presence online. 
                                Harness our expertise in SEO, engage through dynamic social media strategies, captivate audiences with compelling content, and achieve visibility through targeted PPC campaigns. Let's transform your online presence into a captivating story.
                            </motion.p>
                        </div>
                    </div>
                </div>
            </section>
        </Element>
    );
}

export default HomeService;