import React from "react";
import { 
    FaReact, 
    FaAngular, 
    FaHtml5, 
    FaNodeJs,
    FaFigma,
    FaChrome,
    FaWordpress,
    FaPython,
    FaSwift,
    FaApple,
    FaAndroid
} from "react-icons/fa6";
import { 
    SiAdobexd, 
    SiTailwindcss, 
    SiNetlify, 
    SiStrapi, 
    SiNotion, 
    SiContentful,
    SiRender,
    SiHeroku,
    SiMicrosoftexcel,
    SiFramer,
    SiMysql
} from "react-icons/si";
import { motion } from "framer-motion";
import { Carousel } from "@material-tailwind/react";
import CarouselCard from './CarouselCard';
import strategy from '../../utils/services/DigitalStrategy.svg';
import rpa from '../../utils/services/RPA.svg';
import analytics from '../../utils/services/DataAnalytics.svg';
import webDev from '../../utils/services/WebDevelopment.svg';
import appDev from '../../utils/services/AppDevelopment.svg';
import design from '../../utils/services/UserExperience.svg';
import maintenance from '../../utils/services/WebMaintenance.svg';
import cms from '../../utils/services/ContentManagement.svg';
import marketing from '../../utils/services/DigitalMarketing.svg';

const animation = {
    offscreen: {opacity:0},
    onscreen: {opacity:1, transition: {duration: 1.25, type: 'tween'}},
};

const carouselItems = [
    {
        image: strategy,
        title: 'Digital Strategy Development',
        description:
          'Elevate your digital presence with our strategic expertise. We analyze market trends, customer behavior, and industry insights to craft comprehensive digital strategies that align perfectly with your business objectives.',
        icons: [],
    },
    {
        image: rpa,
        title: 'Business Process Automation',
        description:
          'Streamline your operations for maximum efficiency. We leverage cutting-edge technologies like Robotic Process Automation (RPA) and intelligent workflow automation tools to automate repetitive tasks, enhance productivity, and reduce operational costs for your business.',
        icons: [],
    },
    {
      image: marketing,
      title: 'Digital Marketing',
      description:
        'Boost your online visibility and engagement. Our digital marketing services utilize targeted strategies, SEO optimization, and data-driven approaches to enhance your brand’s presence. From social media campaigns to content marketing, we drive meaningful interactions with your audience.',
      icons: [],
    },
    {
      image: webDev,
      title: 'Website Development',
      description:
        'Transform your digital vision into powerful business tools. Our Web Development services employ latest web technologies to engineer websites that are not just visually striking online platforms, but also dynamic software solutions designed to become indispensable tools for your business. With our expertise, your online presence evolves into a strategic asset, driving growth, engagement, and success.',
      icons: [<FaReact />, <FaAngular />, <FaHtml5 />, <FaNodeJs />],
    },
    {
        image: appDev,
        title: 'Mobile Application Development',
        description:
          'Bring your ideas to life with our custom mobile apps. Using cutting-edge app development technologies and frameworks, we design and develop engaging mobile applications for both Apple and Android devices. Your users will enjoy intuitive interfaces and seamless experiences while your business benefits our robust software solutions.',
        icons: [<FaReact />, <FaSwift />, <FaApple />, <FaAndroid />],
    },
    {
      image: design,
      title: 'UI/UX Design',
      description:
        "Enviion's UI/UX Design services blend creativity with data-driven insights to craft interfaces that resonate with your audience. Our designs are intuitive, visually appealing, and highly interactive, ensuring a delightful user experience. From wireframes to final mockups, we meticulously design every element, making your website an immersive and unique journey for visitors.",
      icons: [<FaFigma />, <SiAdobexd />, <SiTailwindcss />, <SiFramer />],
    },
    {
      image: maintenance,
      title: 'Website Maintenance',
      description:
        'Our Website Maintenance services encompass everything from SEO optimization to security updates. We ensure your website ranks high, loads fast, and remains secure against digital threats. Our proactive approach guarantees your site is always up-to-date and optimized, allowing you to focus on your business while we handle the technicalities.',
      icons: [<FaChrome />, <SiRender />, <SiNetlify />, <SiHeroku />],
    },
    {
        image: analytics,
        title: 'Data Analytics',
        description:
          'Uncover actionable insights from your data. We employ robust tools like SQL databases, MySQL, Excel, and MongoDB to analyze your data comprehensively. Our data analytics services help you make informed decisions, optimize processes, and identify growth opportunities.',
        icons: [<FaPython />, <SiMicrosoftexcel />, <SiMysql />],
    },
    {
      image: cms,
      title: 'Content Management System Integration',
      description:
        'Empower your content management effortlessly. We seamlessly integrate headless CMS technologies, providing you with a flexible and intuitive platform to manage your content. Easily update and modify your website’s content without technical hassle.',
      icons: [<FaWordpress />, <SiStrapi />, <SiNotion />, <SiContentful />],
    },
];

const Gallery = () => {
    console.log('Carousel Items:', carouselItems);
    return (
        <div className="max-w-[100%] min-w-[354px] h-fit overflow-hidden">
            <Carousel className="bg-[#181613] place-items-center">
                {carouselItems.map((item, index) => (
                    <CarouselCard key={index} {...item} />
                ))}
            </Carousel>
        </div>
    );
}

export default Gallery;