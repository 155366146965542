import Navbar from '../common/Navbar';
import Ethos from './Ethos';
import HomeService from './HomeService';
import Footer from '../common/Footer';
import Hero from './Hero';
import Process from './Process';
import CallToAction from './CallToAction';

function Home() {
    return (
      <div>
        <Navbar />
        <Hero />
        <Ethos />
        <HomeService />
        <Process />
        <CallToAction />
        <Footer />
      </div>
    );
  }


export default Home;