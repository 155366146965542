import React from "react";
import { motion } from "framer-motion";
import { FaArrowRight } from "react-icons/fa6"; 
import { Element, Link } from "react-scroll";
import above from '../../utils/above.svg';
import agile from '../../utils/agile.svg';
import client from '../../utils/client.svg';
import creative from '../../utils/creative.svg';


const heading = {
    offscreen: {opacity:0},
    onscreen: {opacity:1, transition: {duration: 1.25, type: 'tween'}},
};
const servicesText = {
    offscreen: {opacity:0, y:50},
    onscreen: {opacity:1, y:0, transition: {duration: 1.25, type: 'tween', delay: 0.3}},
};

const CompanyValues = () => {
    return (
        <Element name ="company-value">
            <section className="company-values max-w-[100%] min-w-[340px] my-28 lg:my-32 px-6">
                <div className="grid lg:grid-cols-3 gap-4">
                    <div className="flex flex-col justify-center">
                        <motion.div className=""
                            initial = "offscreen"
                            whileInView= "onscreen"
                            variants = {heading}
                            viewport={{ once: true}}
                        >
                            <h1 className="hero-heading text-5xl md:text-7xl px-10 py-4 text-[#181613]">
                                Company Values
                            </h1>
                        </motion.div>
                        <div className="pb-10 lg:pb-auto pt-2 px-10">
                            <motion.div className="reach-out uppercase text-center whitespace-nowrap text-sm md:text-base"
                                initial = "offscreen"
                                whileInView= "onscreen"
                                variants = {servicesText}
                                viewport={{ once: true}}
                            >
                                <a href="/contact" className='flex items-center pointer-cursor'>
                                    <span className='mr-2'><FaArrowRight/> </span>
                                    <span>Reach Out</span>
                                </a>
                            </motion.div>
                        </div>
                    </div>
                    <div className="grid sm:grid-cols-2 lg:grid-cols-1 items-center justify-center sm:justify-normal">
                        <div className="px-10 lg:px-auto">
                            <motion.div className="about-icon"
                                initial = "offscreen"
                                whileInView= "onscreen"
                                variants = {heading}
                                viewport={{ once: true}}
                            >
                                <img src={above} alt="Enviion Web Development and Digital Consulting Agency Going Above and Beyond" />
                            </motion.div>
                            <motion.h2 className="hero-heading text-[#181613] text-4xl py-4"
                                initial = "offscreen"
                                whileInView= "onscreen"
                                variants = {heading}
                                viewport={{ once: true}}
                            >
                                Above and Beyond
                            </motion.h2>
                            <motion.p className="hero-text text-lg text-[#333] pb-16"
                                initial = "offscreen"
                                whileInView= "onscreen"
                                variants = {servicesText}
                                viewport={{ once: true}}
                            >
                                We go the extra mile to exceed client expectations, ensuring every project is met with unwavering commitment and excellence. 
                            </motion.p>
                        </div>
                        <div className="px-10 lg:px-auto">
                            <motion.div className="about-icon"
                                initial = "offscreen"
                                whileInView= "onscreen"
                                variants = {heading}
                                viewport={{ once: true}}
                            >
                                <img src={client} alt="Enviion Web Development and Digital Consulting Agency Fosters Lasting Client Relationships" />
                            </motion.div>
                            <motion.h2 className="hero-heading text-[#181613] text-4xl py-4"
                                initial = "offscreen"
                                whileInView= "onscreen"
                                variants = {heading}
                                viewport={{ once: true}}
                            >
                                Client Commitment
                            </motion.h2>
                            <motion.p className="hero-text text-lg text-[#333] pb-16"
                                initial = "offscreen"
                                whileInView= "onscreen"
                                variants = {servicesText}
                                viewport={{ once: true}}
                            >
                                We are dedicated to nurturing lasting client relationships built on trust, communication and mutual growth.
                            </motion.p>
                        </div>
                    </div>
                    <div className="grid sm:grid-cols-2 lg:grid-cols-1 items-center justify-center sm:justify-normal">
                        <div className="px-10 lg:px-auto">
                            <motion.div className="about-icon"
                                initial = "offscreen"
                                whileInView= "onscreen"
                                variants = {heading}
                                viewport={{ once: true}}
                            >
                                <img src={agile} alt="Enviion Web Development and Digital Consulting Agency Practices Agile Innovation" />
                            </motion.div> 
                            <motion.h2 className="hero-heading text-[#181613] text-4xl py-4"
                                initial = "offscreen"
                                whileInView= "onscreen"
                                variants = {heading}
                                viewport={{ once: true}}
                            >
                                Agile Innovation
                            </motion.h2>
                            <motion.p className="hero-text text-lg text-[#333] pb-16"
                                initial = "offscreen"
                                whileInView= "onscreen"
                                variants = {servicesText}
                                viewport={{ once: true}}
                            >
                                Our commitment to innovation means we stay at the forefront of tech trends while adapting to evolving project dynamics and client requirements.
                            </motion.p>
                        </div>
                        <div className="px-10 lg:px-auto"> 
                            <motion.div className="about-icon"
                                initial = "offscreen"
                                whileInView= "onscreen"
                                variants = {heading}
                                viewport={{ once: true}}
                            >
                                <img src={creative} alt="Enviion Web Development and Digital Consulting Agency Instills Creativity Into Projects" />
                            </motion.div>
                            <motion.h2 className="hero-heading text-[#181613] text-4xl py-4"
                                initial = "offscreen"
                                whileInView= "onscreen"
                                variants = {heading}
                                viewport={{ once: true}}
                            >
                                Creative Excellence 
                            </motion.h2>
                            <motion.p className="hero-text text-lg text-[#333] pb-16"
                                initial = "offscreen"
                                whileInView= "onscreen"
                                variants = {servicesText}
                                viewport={{ once: true}}
                            >
                                We instill creativity into every aspect of our work, shaping unique and captivating digital experiences that leave a lasting impact.
                            </motion.p>
                        </div>
                    </div>
                </div>
            </section>
        </Element>
    );
}

export default CompanyValues;
