import Navbar from '../common/Navbar';
import Landing from './Landing';
import Gallery from './Gallery';
import CallToAction from './CallToAction';
import Footer from '../common/Footer';

function Services() {
    return (
      <>
        <Navbar />
        <Landing />
        <Gallery />
        <CallToAction />
        <Footer/>
      </>
    );
  }


export default Services;